import React from 'react'
import { useRouteMatch } from 'react-router'
import PropTypes from 'prop-types'

import { _ } from 'Services/I18n'

import { SEARCH_TYPE } from 'Constants/ids'
import Labels from 'Constants/labels'
import { Filter, FilterDateRange, FilterRange } from 'Components/Blocks'
import { useBaseTheme, useHydrated } from 'Hooks'

import map from 'lodash/map'
import get from 'lodash/get'

function Filters({
  categoryFilterOptions,
  categoryValue,
  customFilters,
  getActiveFilters,
  typeFilterOptions,
  onChangeCategoryFilter,
  onChangeFilter,
  onChangeRangeFilter,
  onChangeDateRangeFilter,
}) {
  const { labels, serviceCategory, showAvailability } = useBaseTheme()
  const hydrated = useHydrated()

  const match = useRouteMatch()
  const searchType = get(match, 'params.searchType')
  const bucketType = get(match, 'params.bucketType')

  return (
    hydrated && (
      <>
        {serviceCategory ? (
          <>
            <Filter
              items={categoryFilterOptions}
              label={get(labels, Labels.FILTER_BY_CATEGORY)}
              limit={10}
              type="radio"
              value={categoryValue}
              onSelect={onChangeCategoryFilter}
            />
            <Filter
              items={typeFilterOptions}
              label={get(labels, Labels.FILTER_BY_TYPE)}
              limit={10}
              showClear={false}
              type="radio"
              value={categoryValue}
              onSelect={onChangeCategoryFilter}
            />
          </>
        ) : null}

        {bucketType === 'suburb' && (
          <FilterRange onChange={onChangeRangeFilter} />
        )}

        {showAvailability && (
          <FilterDateRange onChange={onChangeDateRangeFilter} />
        )}

        {searchType === SEARCH_TYPE.BUSINESSES && (
          <>
            {map(customFilters, (customFilter, index) => {
              return get(customFilter, 'options.length', 0) ? (
                <Filter
                  activeFilters={getActiveFilters(customFilter)}
                  items={customFilter.options}
                  key={index}
                  label={`${_('filterBy.by')} ${customFilter.label}`}
                  limit={10}
                  onSelect={onChangeFilter(`${customFilter.name}FilterIds`)}
                />
              ) : null
            })}
          </>
        )}

        {searchType === SEARCH_TYPE.SERVICES && (
          <>
            {map(customFilters, (customFilter, index) => {
              return get(customFilter, 'options.length', 0) ? (
                <Filter
                  activeFilters={getActiveFilters({
                    name: 'customFilterOptions',
                    id: customFilter.id,
                  })}
                  items={customFilter.options}
                  key={index}
                  label={`${_('filterBy.by')} ${customFilter.label}`}
                  limit={10}
                  onSelect={onChangeFilter(
                    `customFilterOptions`,
                    customFilter.id,
                  )}
                />
              ) : null
            })}
          </>
        )}
      </>
    )
  )
}

Filters.defaultProps = {
  categoryValue: null,
}

Filters.propTypes = {
  categoryFilterOptions: PropTypes.array.isRequired,
  categoryValue: PropTypes.object,
  customFilters: PropTypes.array.isRequired,
  getActiveFilters: PropTypes.func.isRequired,
  typeFilterOptions: PropTypes.array.isRequired,
  onChangeCategoryFilter: PropTypes.func.isRequired,
  onChangeDateRangeFilter: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeRangeFilter: PropTypes.func.isRequired,
}

export default Filters
