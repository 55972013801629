import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Error404 } from 'Assets/Svg/Undraw'

import { Flex, Text as TextUI, Button, Icon } from 'Components/UI'

export const RootContainer = styled(Flex)`
  background-color: ${themeGet('colors.bg')};

  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  @media print {
    background-color: ${themeGet('colors.white')};
  }
`

export const PublicContainer = styled(Flex)`
  min-height: calc(100vh - ${themeGet('sizes.header')});
  min-height: calc(100dvh - ${themeGet('sizes.header')});
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-color: ${themeGet('colors.bg')};
  position: relative;

  @media screen and (max-width: ${themeGet('breakpoints.2')}) {
    background-color: ${themeGet('colors.white')};
  }

  @media print {
    background-color: ${themeGet('colors.white')};
  }
`

export const NotFoundImage = styled(Icon).attrs({
  glyph: Error404,
  mb: 5,
})`
  width: 100%;
  height: 100%;

  max-height: 350px;

  @media screen and (max-width: ${themeGet('breakpoints.0')}) {
    max-height: 200px;
  }
`

export const NotFoundContent = styled(Flex)`
  background-color: ${themeGet('colors.white')};

  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
`

export const Text = styled(TextUI).attrs({
  is: 'span',
  fontSize: [14, 16, 16, 22],
})`
  color: #445a64;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`

export const GoBackButton = styled(Button).attrs({ mt: 5 })`
  font-size: 12px;
  text-transform: uppercase;
  width: 190px;
  border-radius: 4px;

  &:hover {
    opacity: 0.9;
  }
`
