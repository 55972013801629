import React from 'react'

import map from 'lodash/map'

import { useBaseTheme } from 'Hooks'

import {
  LinkLabel,
  LinkExternal,
  StyledButton,
  HeaderLinksContainer,
} from './styles'

const HeaderLinks = () => {
  const {
    primaryColor,
    primaryTextColor,
    roundedBorder,
    header,
    headerPrimaryColor,
  } = useBaseTheme()

  return (
    <HeaderLinksContainer>
      {map(header, (link, index) => {
        const isButton = link.is_button
        const isPrimary = link.is_primary

        if (isButton) {
          return (
            <LinkExternal href={link.url} key={index} target={link.target}>
              <StyledButton
                bc={isPrimary ? primaryColor : headerPrimaryColor}
                bg={isPrimary ? primaryColor : 'transparent'}
                color={isPrimary ? primaryTextColor : headerPrimaryColor}
                rounded={roundedBorder}
              >
                {link.label}
              </StyledButton>
            </LinkExternal>
          )
        }
        return (
          <LinkExternal href={link.url} key={index} target={link.target}>
            <LinkLabel color={isPrimary ? primaryColor : headerPrimaryColor}>
              {link.label}
            </LinkLabel>
          </LinkExternal>
        )
      })}
    </HeaderLinksContainer>
  )
}

export default HeaderLinks
