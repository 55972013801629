import React, { useState, useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import FsLightbox from 'fslightbox-react'
import map from 'lodash/map'
import get from 'lodash/get'
import compact from 'lodash/compact'
import toArray from 'lodash/toArray'

import { useBaseTheme, useHydrated } from 'Hooks'

import { VideoPlayer, WishlistToggle } from 'Components/Blocks'

import { MERCHANT_PAGE_LAYOUT } from 'Constants/ids'

import MerchantSlider from '../MerchantSlider'

import {
  ImageBg,
  Container,
  SecondaryImages,
  SecondaryImage,
  Wrapper,
  WishlistToggleContainer,
} from './styles'

function MerchantImages({ merchant, ...rest }) {
  if (!merchant) {
    return null
  }

  const hydrated = useHydrated()
  const { isLargeScreen } = useBaseTheme()
  const [isModalOpen, setModalOpen] = useState(false)
  const [modalImageIndex, setModalImageIndex] = useState(0)
  const videoRef = useRef()

  const isLayout2 =
    get(merchant, 'merchant_page_layout', MERCHANT_PAGE_LAYOUT.LAYOUT_1) ===
    MERCHANT_PAGE_LAYOUT.LAYOUT_2

  const [showSlider, setShowSlider] = useState(!isLayout2 || !isLargeScreen)

  useEffect(() => {
    setShowSlider(!isLayout2 || !isLargeScreen)
  }, [isLargeScreen])

  const handleImageClick = useCallback(index => {
    setModalImageIndex(index)
    setModalOpen(!isModalOpen)
  })

  const images = compact(toArray(get(merchant, 'images.original')))
  const videoUrl = merchant.video_url
  const showVideoFirst = merchant.show_video_first

  if (!images?.length && !videoUrl) {
    return null
  }

  // Show slider for SSR due to rendering issues
  if (showSlider || !hydrated) {
    return (
      <MerchantSlider
        images={images}
        mb={[3, 3, 0]}
        merchant={merchant}
        mt={[3, 3, 4]}
      />
    )
  }

  const lightBoxSources = () => {
    const sources = [...images]

    if (videoUrl) {
      const videoSource = (
        <div style={{ width: '100vw', height: '100vh' }}>
          <VideoPlayer light={false} playing={false} url={videoUrl} />
        </div>
      )

      if (showVideoFirst) {
        sources.unshift(videoSource)
      } else {
        sources.push(videoSource)
      }
    }

    return sources
  }

  const renderVideo = () => {
    return (
      <VideoPlayer
        className="video-player"
        playing={false}
        ref={videoRef}
        style={{ borderRadius: '4px', overflow: 'hidden' }}
        url={videoUrl}
        onClickPreview={() => {
          // Show the thumbnail again since we are not playing the video
          // when the user clicks on the video, we open the lightbox
          videoRef.current.showPreview()

          handleImageClick(showVideoFirst ? 0 : images.length)
        }}
      />
    )
  }

  const renderImage = (src, index) => {
    const isVideoFirst = videoUrl && showVideoFirst
    return (
      <ImageBg
        src={src}
        onClick={() => handleImageClick(isVideoFirst ? index + 1 : index)}
      />
    )
  }

  const renderSecondary = () => {
    const prependVideo = videoUrl && showVideoFirst
    const appendVideo = videoUrl && !showVideoFirst
    const imageCount = images.length

    // No images so there will never be any secondary items
    if (imageCount === 0) {
      return null
    }

    // Single image
    if (imageCount === 1) {
      // Single image and no video, no secondary items
      if (!videoUrl) {
        return null
      }

      return (
        <Container>
          {prependVideo ? renderImage(images[0], 0) : renderVideo()}
        </Container>
      )
    }

    // Two images
    if (imageCount === 2) {
      // No video or video is appended
      if (!videoUrl || appendVideo) {
        return (
          <Container flexDirection={appendVideo ? 'column' : 'row'}>
            {renderImage(images[1], 1)}
            {appendVideo && renderVideo()}
          </Container>
        )
      }

      // Video is prepended, show both images
      return (
        <Container flexDirection="column">
          {map(images, (image, index) => (
            <SecondaryImage key={index}>
              {renderImage(image, index)}
            </SecondaryImage>
          ))}
        </Container>
      )
    }

    // Three images and no video
    if (imageCount === 3 && !videoUrl) {
      return (
        <Container flexDirection="column">
          {map(images.slice(1, imageCount), (image, index) => (
            <SecondaryImage key={index}>
              {renderImage(image, index + 1)}
            </SecondaryImage>
          ))}
        </Container>
      )
    }

    // Three or more images
    const offset = appendVideo ? 1 : 0
    const items = appendVideo ? images.slice(offset, imageCount) : images

    return (
      <Container>
        <SecondaryImages>
          {map(items, (image, index) => (
            <SecondaryImage key={index}>
              {renderImage(image, index + offset)}
            </SecondaryImage>
          ))}
          {appendVideo && renderVideo()}
        </SecondaryImages>
      </Container>
    )
  }

  const renderPrimary = () => {
    if (videoUrl && (showVideoFirst || !images.length)) {
      return renderVideo()
    }

    return renderImage(images[0], 0)
  }

  return (
    <>
      <Wrapper {...rest}>
        <Container>
          <WishlistToggleContainer>
            <WishlistToggle token={merchant.token} />
          </WishlistToggleContainer>
          {renderPrimary()}
        </Container>
        {renderSecondary()}
      </Wrapper>

      <FsLightbox
        exitFullscreenOnClose
        sourceIndex={modalImageIndex}
        sources={lightBoxSources()}
        toggler={isModalOpen}
        type="image"
      />
    </>
  )
}

MerchantImages.defaultProps = {
  merchant: null,
}

MerchantImages.propTypes = {
  merchant: PropTypes.object,
}

export default MerchantImages
