import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'

import HTMLEllipsis from 'react-lines-ellipsis/lib/html'

import get from 'lodash/get'

import { Flex } from 'Components/UI'

import { useBaseTheme } from 'Hooks'

import { getFormattedNextAvailableDate } from 'Services/Utils/service'

import BookingWidgetModal from '../BookingWidgetModal'
import ServiceDetailsModal from '../ServiceDetailsModal'
import ServiceAvailability from '../ServiceAvailability'

import { Labels, Price } from '../ServiceCard/Components'

import {
  Wrapper,
  PrimaryContainer,
  Name,
  Details,
  DetailCollapse,
} from './styles'

const ServiceRow = ({
  availabilityDate,
  availability,
  breakpoint,
  merchant,
  service,
  servicesNextAvailableDate,
  showDescription,
  truncateLines,
}) => {
  const [showDetailsModal, setShowDetailsModal] = useState()
  const [bookingWidgetUrl, setBookingWidgetUrl] = useState()

  const { isVoucherRequired } = useBaseTheme()

  const name = get(service, 'name')
  const description = get(service, 'description')
  const duration = get(service, 'duration')
  const locations = get(service, 'locations', [])
  const isCustomBookingUrl = get(service, 'is_custom_booking_url', false)

  const { business, reviews } = merchant

  const mainCategories = get(service, 'assigned_categories.category_1', [])
  const subCategories = get(service, 'assigned_categories.category_2', [])
  const categories = subCategories.length ? subCategories : mainCategories

  const handleShowWidgetClick = showAvailabilityWidget => {
    const bookingWidgetDateParam =
      availabilityDate && !isCustomBookingUrl
        ? `&date=${availabilityDate.start.format('YYYY-MM-DD')}`
        : ''

    const availabilityWidgetParam =
      isVoucherRequired && showAvailabilityWidget ? '&book_now=no' : ''

    const url = `${get(
      service,
      'booking_widget_url',
    )}${bookingWidgetDateParam}${availabilityWidgetParam}`

    if (breakpoint === 'mobile' || isCustomBookingUrl) {
      const newWindow = window.open()
      newWindow.opener = null
      newWindow.location = url

      return
    }

    setShowDetailsModal(false)
    setBookingWidgetUrl(url)
  }

  const handleSelect = () => {
    setShowDetailsModal(true)
  }

  const renderTruncatedDescription = useMemo(() => {
    return (
      <HTMLEllipsis
        basedOn="letters"
        maxLine={truncateLines}
        unsafeHTML={description}
      />
    )
  }, ['description'])

  const renderAvailability = () => {
    let availabilityLabel = ''

    if (availabilityDate) {
      availabilityLabel = availability
    } else {
      // No date selected, show next available date
      availabilityLabel = getFormattedNextAvailableDate(
        get(servicesNextAvailableDate, get(service, 'id')),
        service.start_date,
        service.end_date,
      )
    }

    return (
      <ServiceAvailability
        availability={availabilityLabel}
        color="#52525B"
        flex="1"
        isCustomBookingUrl={isCustomBookingUrl}
        onShowAvailability={() => handleShowWidgetClick(true)}
      />
    )
  }

  return (
    <Wrapper individual={showDescription ? 1 : 0}>
      <PrimaryContainer onClick={handleSelect}>
        <Details mb={duration ? '' : '10px'}>
          <Flex className="details-left" flex="1" flexDirection="column">
            <Name fontWeight={700}>{name}</Name>

            <Labels fontSize={12} service={service} />
          </Flex>
          <Price flexDirection="column" service={service} />
        </Details>

        {description && showDescription && (
          <DetailCollapse isOpen>{renderTruncatedDescription}</DetailCollapse>
        )}

        {renderAvailability()}
      </PrimaryContainer>

      {showDetailsModal && (
        <ServiceDetailsModal
          business={business}
          categories={categories}
          locations={locations}
          reviews={reviews}
          service={service}
          onBook={() => handleShowWidgetClick(false)}
          onClose={() => setShowDetailsModal(false)}
        />
      )}

      {bookingWidgetUrl && (
        <BookingWidgetModal
          url={bookingWidgetUrl}
          onClose={() => setBookingWidgetUrl(false)}
        />
      )}
    </Wrapper>
  )
}

ServiceRow.defaultProps = {
  availability: '',
  availabilityDate: null,
  showDescription: false,
  truncateLines: 5,
  merchant: {},
  service: {},
  servicesNextAvailableDate: null,
}

ServiceRow.propTypes = {
  availability: PropTypes.string,
  availabilityDate: PropTypes.shape({
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  }),
  breakpoint: PropTypes.string.isRequired,
  merchant: PropTypes.object,
  service: PropTypes.object,
  servicesNextAvailableDate: PropTypes.object,
  showDescription: PropTypes.bool,
  truncateLines: PropTypes.number,
}

export default ServiceRow
