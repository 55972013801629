import { _ } from 'Services/I18n'

import map from 'lodash/map'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import find from 'lodash/find'
import omit from 'lodash/omit'
import _sortBy from 'lodash/sortBy'

import * as Utils from 'Services/Utils'

import { MapPinIcon, StarIcon, ListAltIcon, HomeIcon } from 'Assets/Svg/General'

export const processLocations = (locations, type) => {
  const options = map(locations, location => ({
    label: location.full_name,
    value: location,
    type,
    metadata: {
      type: 'h2',
    },
  }))

  return {
    label: {
      title: _('common.locations'),
      icon: MapPinIcon,
    },
    options,
  }
}

export const processMerchants = (merchants, type) => {
  const options = map(merchants, merchant => ({
    label: merchant.full_name,
    value: merchant,
    type,
    metadata: {
      type: 'h2',
    },
  }))

  return {
    label: {
      title: _('common.businesses'),
      icon: HomeIcon,
    },
    options,
  }
}
export const processFeaturedCategories = ({
  categories = [],
  type,
  categoryValue,
  showInvisibleCategory1 = false,
  sortAlphabetically = false,
}) => {
  const { categoryId, categoryType } = Utils.getCategoryProps(categoryValue)

  const visibleCategories = showInvisibleCategory1
    ? categories
    : categories.filter(category => category.is_visible)

  const options = map(visibleCategories, category => {
    const isActive =
      category.bucket_type === categoryType && category.bucket_id === categoryId

    const value = { ...category }
    const categoryTwoItems = get(value, 'category_2', [])

    value.category_2 = categoryTwoItems.filter(categoryTwo => {
      // Filter out invisible
      if (!categoryTwo.is_visible) {
        return false
      }

      // Filter out supplementary from category 2 items
      if (type === 'non_supplementary') {
        return !categoryTwo.supplementary
      }

      return true
    })

    if (sortAlphabetically) {
      value.category_2 = _sortBy(value.category_2, 'bucket_name')
    }

    return {
      label: category.bucket_name,
      labelIcon: true,
      labelIconActive: isActive,
      value,
      type: 'categories',
      metadata: {
        type: 'h2',
      },
    }
  })

  if (sortAlphabetically) {
    options.sort((a, b) => a.label.localeCompare(b.label))
  }

  return {
    featured: true,
    label: {
      title: _('common.mostPopular'),
      icon: StarIcon,
    },
    options,
  }
}

export const processFeaturedLocations = ({ locations = [] }) => {
  const options = map(locations, location => {
    return {
      label: location?.region_name,
      labelIcon: false,
      labelIconActive: false,
      value: {
        bucket_id: location?.id,
        bucket_name: location?.region_name,
        bucket_slug: location?.region_slug,
        full_name: location?.region_name,
      },
      type: 'region',
      metadata: {
        type: 'h2',
      },
    }
  })

  return {
    featured: true,
    label: {
      title: _('common.mostPopular'),
      icon: StarIcon,
    },
    options,
  }
}

export const processSupplementaryCategories = ({
  categories = [],
  categoryValue,
}) => {
  const categoryTwoItems = []

  // Build array of only supplementary category two items (symptoms)
  categories.forEach(option => {
    const supplementaryItems = get(option, 'category_2', []).filter(
      item => item.is_visible && item.supplementary,
    )
    categoryTwoItems.push(...supplementaryItems)
  })

  const options = map(categoryTwoItems, category => {
    const isActive =
      category.bucket_type === get(categoryValue, 'value.bucket_type') &&
      category.bucket_id === get(categoryValue, 'value.bucket_id')

    return {
      label: category.bucket_name,
      labelIcon: true,
      labelIconActive: isActive,
      value: category,
      type: 'categories',
      metadata: {
        type: 'h2',
      },
    }
  })

  return {
    featured: true,
    label: {
      title: _('common.mostPopular'),
      icon: StarIcon,
    },
    options,
  }
}

export const processCategories = (categories, type) => {
  let options = []
  Object.entries(categories).map(([key, value]) => {
    const categoryOptions = map(value, category => ({
      label: category.bucket_name,
      value: {
        ...category,
        bucket_type: key,
      },
      type,
      metadata: {
        type: 'h2',
      },
    }))

    options = [...options, ...categoryOptions]
    return null
  })

  return {
    featured: false,
    label: {
      title: _('common.matchingCategories'),
      icon: ListAltIcon,
    },
    options,
  }
}

export const processBucketForSearchPathParams = (
  {
    bucket_type: bucketType,
    bucket_id: bucketId,
    bucket_name: bucketName,
    full_name: bucketFullName,
  },
  currentPage = 1,
) => {
  return {
    bucketType,
    bucketId,
    bucketName,
    bucketFullName,
    currentPage,
  }
}

export const processBucketForAllResultsSearchPathParams = (currentPage = 1) => {
  return {
    bucketType: 'results',
    currentPage,
  }
}

// When marketplace service type is online
export const processBucketForOnlineSearchPathParams = (
  categoryBucket,
  currentPage = 1,
) => {
  const {
    bucket_type: bucketType,
    bucket_id: bucketId,
    bucket_name: bucketFullName,
  } = get(categoryBucket, 'category_2', categoryBucket)

  if (!bucketType) {
    return {
      bucketType: 'online',
      currentPage,
    }
  }

  const bucketTypeID = bucketType.replace('category_', '')

  return {
    bucketType: Number(bucketTypeID),
    bucketId,
    bucketFullName,
    currentPage,
  }
}

export const processBuildCategoryValue = (selectedCategory, categories) => {
  if (!selectedCategory || !selectedCategory.value) return null

  // If the passed in category isn't fully qualified ie parsed from URL params
  const isCategoryFromURL = !selectedCategory.value.full_name

  return reduce(
    categories,
    (acc, category) => {
      if (
        category.bucket_id === selectedCategory.value.bucket_id &&
        category.bucket_type === selectedCategory.value.bucket_type
      ) {
        return isCategoryFromURL
          ? {
              label: category.bucket_name,
              value: {
                ...category,
                category_2: [],
              },
            }
          : selectedCategory
      }

      const subCategories = get(category, 'category_2')
      const subCategory = find(
        subCategories,
        cat =>
          cat.bucket_id === selectedCategory.value.bucket_id &&
          cat.bucket_type === selectedCategory.value.bucket_type,
      )

      if (subCategory) {
        return {
          label: category.bucket_name,
          value: {
            ...category,
            category_2: subCategory,
          },
        }
      }

      return acc
    },
    {},
  )
}

export const processFindCategoryValueFromUrl = ({
  categoryId,
  categoryFullName,
  categories,
}) => {
  let categoryValueFromURL = {}
  find(categories, primaryCategory => {
    if (
      primaryCategory.bucket_id === Number(categoryId) &&
      primaryCategory.bucket_slug === categoryFullName
    ) {
      categoryValueFromURL = omit(primaryCategory, 'category_2')
      return true
    }
    return find(get(primaryCategory, 'category_2', []), secondaryCategory => {
      if (
        secondaryCategory.bucket_id === Number(categoryId) &&
        secondaryCategory.bucket_slug === categoryFullName
      ) {
        categoryValueFromURL = omit(secondaryCategory, 'category_3')
        return true
      }
      return false
    })
  })
  return categoryValueFromURL
}
