import styled, { css } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { Flex, Text, Icon, Button } from 'Components/UI'
import { Wishlist } from 'Assets/Svg/Undraw'

export const EmptyContainer = styled(Flex).attrs({
  mt: 4,
})`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const EmptyTitle = styled(Text).attrs({
  mt: [4, 4, 0],
  fontSize: 2,
})`
  color: ${themeGet('colors.black')};
  letter-spacing: 0.7px;
  font-weight: bold;
`

export const EmptyImage = styled(Icon).attrs({
  glyph: Wishlist,
  mt: 4,
})`
  width: 200px;
`

export const EmptyText = styled(Text).attrs({
  mt: 4,
  fontSize: 2,
})`
  color: ${themeGet('colors.boulder')};
  line-height: 1.4;
  text-align: center;
  svg {
    fill: ${themeGet('colors.red')};
  }
`

const roundedborderButtonCss = ({ roundedborder }) =>
  roundedborder &&
  css`
    border-radius: ${themeGet('borderRadius.2')} !important;
    overflow: hidden;
  `

export const StyledButton = styled(Button).attrs({
  mt: 4,
})`
  background-color: ${({ background }) =>
    background || themeGet('colors.goldenrod')};

  border-radius: ${themeGet('borderRadius.1')};

  color: ${({ color }) => color || themeGet('colors.black')};
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  marwidth: 215px;
  min-width: 120px;
  white-space: nowrap;

  transition:
    all 0.2s ease 0s,
    -webkit-transform 0.2s ease 0s;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 9px 2px;
    transition:
      all 0.2s ease 0s,
      -webkit-transform 0.2s ease 0s;
  }

  ${roundedborderButtonCss};
`
