import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import Labels from 'Constants/labels'
import { useBaseTheme } from 'Hooks'
import { Box } from 'Components/UI'

import { PrintContainer } from './styles'

function ServicePrint({ service }) {
  const { labels } = useBaseTheme()
  const location = service.service.locations[0]

  return (
    <PrintContainer mb={4}>
      <Box fontSize="16px">{service.service.name}</Box>
      <Box mt={1}>
        <strong>
          {get(labels, Labels.TITLE_SERVICE_ORGANISED_BY, 'Organised by')}
        </strong>
        : {service.business.name}
      </Box>
      {location?.full_address && (
        <Box>
          <strong>Address:</strong> {location.name} {location.full_address}
        </Box>
      )}
      {location?.phone_number && (
        <Box>
          <strong>Phone:</strong> {location.phone_number}
        </Box>
      )}
      {service.business.website && (
        <Box>
          <strong>Website:</strong> {service.business.website}
        </Box>
      )}
    </PrintContainer>
  )
}

ServicePrint.propTypes = {
  service: PropTypes.object.isRequired,
}

export default ServicePrint
