import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { getSearchDate } from 'Store/Selectors/marketplace'

import FilterDateRange from './FilterDateRange'

const selector = createStructuredSelector({
  searchDate: getSearchDate,
})

const actions = {}

export default connect(selector, actions)(FilterDateRange)
