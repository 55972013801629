import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { closeAltGlyph } from 'Assets/Svg'

import Icon from '../Icon'
import ButtonAlt from '../ButtonAlt'
import Flex from '../Flex'

const roundedCss = ({ rounded }) => css`
  border-radius: ${rounded
    ? themeGet('borderRadius.2')
    : themeGet('borderRadius.1')};
`

export const CloseIcon = styled(Icon).attrs({
  glyph: closeAltGlyph,
  size: 20,
})`
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  stroke-width: 0;
  color: ${themeGet('colors.gray')};
`

export const InfoWrapper = styled(Flex).attrs({
  px: 22,
  pb: 20,
  pt: [20, 20, 0],
  justifyContent: ['center', 'center', 'center'],
})`
  gap: 10px;
`

export const InfoButton = styled(ButtonAlt).attrs({})`
  text-transform: capitalize;
  ${roundedCss};
`

export const DatePickerWrapper = styled(Flex)`
  background-color: #ffffff;
  border-radius: 4px;
  width: 100%;

  ${roundedCss}

  .DateRangePicker {
    width: 100%;
    max-height: 55px;

    @media screen and (max-width: ${themeGet('breakpoints.0')}) {
      max-height: 43px;
    }
  }

  .DateRangePickerInput {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 4px;
    border: 1px solid ${themeGet('colors.lightGrey')} !important;

    ${roundedCss}

    .DateInput {
      width: 85px;
      border-radius: 4px;
      ${roundedCss}

      input {
        width: 100%;
        color: ${themeGet('colors.header')};
        font-size: 14px;
        font-weight: 500;
        margin: 0 auto;
        padding-left: 0;
        cursor: default;
        border: none;
        height: 100%;

        ${roundedCss}

        &::placeholder {
          color: ${themeGet('colors.header')};
          font-size: 14px;
          font-weight: 500;
        }

        @media screen and (max-width: ${themeGet('breakpoints.2')}) {
          font-size: 14px;
          height: 38px;
          padding: 6px 7px 6px 23px;

          &::placeholder {
            font-size: 14px;
          }
        }

        @media screen and (max-width: ${themeGet('breakpoints.0')}) {
          font-size: 14px;
          padding: 6px 7px 6px 23px;

          &::placeholder {
            font-size: 14px;
          }
        }
      }

      .DateInput_fang {
        display: none;
      }
    }

    &_calendarIcon {
      color: ${props => props.secondarycolor};
      margin: 0 0 0 10px;

      @media screen and (max-width: ${themeGet('breakpoints.0')}) {
        margin: 0;
      }
    }
  }

  .DateInput_input {
    padding: 11px 0 11px 11px;
  }

  .DateInput__small {
    width: 120px !important;
  }

  .DateInput_input__small {
    padding: 5px 7px;
    height: 36px !important;
  }

  .DateInput_input__small#date_picker_start {
    margin: 0;
  }

  #date_picker_start {
    cursor: pointer;
  }

  .DateInput:has(#date_picker_end) {
    flex: 1;
    margin-right: 15px;
  }

  #date_picker_end {
    padding-left: 0 !important;
    cursor: pointer;
  }

  .DateRangePicker_picker,
  .DayPicker,
  .DayPicker_transitionContainer,
  .CalendarMonthGrid,
  .CalendarMonth {
    ${roundedCss}
  }

  .DateRangePickerInput_arrow {
    display: flex;
    align-items: center;
    padding: 0 10px 0 5px;
  }
`
