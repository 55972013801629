import { createAsyncAction } from 'Store/utils'

import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import moment from 'moment'

import apiCall from 'Services/Api'

export const LOAD_CLIENT_DETAILS = createAsyncAction(
  'marketplace/LOAD_CLIENT_DETAILS',
)

export const LOAD_FEATURED_MERCHANTS = createAsyncAction(
  'marketplace/LOAD_FEATURED_MERCHANTS',
)

export const LOAD_FEATURED_CATEGORIES = createAsyncAction(
  'marketplace/LOAD_FEATURED_CATEGORIES',
)

export const LOAD_FEATURED_LOCATIONS = createAsyncAction(
  'marketplace/LOAD_FEATURED_LOCATIONS',
)

export const LOAD_ALL_CATEGORIES = createAsyncAction(
  'marketplace/LOAD_ALL_CATEGORIES',
)

export const LOAD_CATEGORIES_TAGS = createAsyncAction(
  'marketplace/LOAD_CATEGORIES_TAGS',
)

export const LOAD_MARKETPLACE_SUGGESTIONS = createAsyncAction(
  'marketplace/LOAD_MARKETPLACE_SUGGESTIONS',
)

export const LOAD_MARKETPLACE_CATEGORY_SUGGESTIONS = createAsyncAction(
  'marketplace/LOAD_MARKETPLACE_CATEGORY_SUGGESTIONS',
)

export const LOAD_CATEGORIES = createAsyncAction('marketplace/LOAD_CATEGORIES')

export const LOAD_CATEGORY = createAsyncAction('marketplace/LOAD_CATEGORY')

export const SET_CATEGORY_VALUE = 'marketplace/SET_CATEGORY_VALUE'
export const SET_SEARCH_VALUE = 'marketplace/SET_SEARCH_VALUE'
export const SET_SEARCH_DATE = 'marketplace/SET_SEARCH_DATE'
export const SET_SEARCH_DATE_PICKER_OPEN_STATUS =
  'marketplace/SET_SEARCH_DATE_PICKER_OPEN_STATUS'

export const LOAD_MERCHANT = createAsyncAction('marketplace/LOAD_MERCHANT')
export const SEARCH_MERCHANTS = createAsyncAction(
  'marketplace/SEARCH_MERCHANTS',
)
export const LOAD_SERVICE = createAsyncAction('services/LOAD_SERVICE')
export const SEARCH_SERVICES = createAsyncAction('marketplace/SEARCH_SERVICES')
export const SEARCH_NEAR_MERCHANTS = createAsyncAction(
  'marketplace/SEARCH_NEAR_MERCHANTS',
)
export const WIDGET_AUTHENTICATE = createAsyncAction(
  'marketplace/WIDGET_AUTHENTICATE',
)

export const LOAD_SERVICES_AVAILABILITY = createAsyncAction(
  'marketplace/LOAD_SERVICES_AVAILABILITY',
)

export const LOAD_SERVICES_NEXT_AVAILABLE_DATE = createAsyncAction(
  'marketplace/LOAD_SERVICES_NEXT_AVAILABLE_DATE',
)

export const LOAD_SERVICE_SURROUNDING_DATES = createAsyncAction(
  'marketplace/LOAD_SERVICE_SURROUNDING_DATES',
)

export const loadClientDetails = ({ ssr } = { ssr: false }) =>
  apiCall({
    endpoint: '/marketplace/client',
    types: LOAD_CLIENT_DETAILS,
    ssr,
  })

export const loadSuggestions = ({ term, serviceType }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/suggestions`,
    types: LOAD_MARKETPLACE_SUGGESTIONS,
    query: {
      term,
      serviceType,
    },
  })

export const loadCategorySuggestions = ({ term, serviceType }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/category_suggestions`,
    types: LOAD_MARKETPLACE_CATEGORY_SUGGESTIONS,
    query: {
      term,
      serviceType,
    },
  })

export const loadFeaturedCategories = ({ ssr } = { ssr: false }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/featured_categories`,
    types: LOAD_FEATURED_CATEGORIES,
    ssr,
  })

export const loadFeaturedLocations = ({ ssr } = { ssr: false }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/locations/featured`,
    types: LOAD_FEATURED_LOCATIONS,
    ssr,
  })

export const loadFeaturedMerchants = ({ ssr } = { ssr: false }) =>
  apiCall({
    endpoint: '/marketplace/featured_merchants',
    types: LOAD_FEATURED_MERCHANTS,
    ssr,
  })

export const loadCategories = () =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/categories`,
    types: LOAD_CATEGORIES,
  })

export const loadCategory = ({ categoryBucketType, categoryId, ssr = false }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/categories/${categoryBucketType}/${categoryId}`,
    types: LOAD_CATEGORY,
    ssr,
  })

export const loadAllCategories = ({ ssr = false }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/categories/all`,
    types: LOAD_ALL_CATEGORIES,
    ssr,
  })

export const loadCategoriesTags = () =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/categories/tags`,
    types: LOAD_CATEGORIES_TAGS,
  })

export const setCategoryValue = categoryValue => ({
  type: SET_CATEGORY_VALUE,
  categoryValue,
})

export const setSearchValue = searchValue => ({
  type: SET_SEARCH_VALUE,
  searchValue,
})

export const setSearchDate = searchDate => ({
  type: SET_SEARCH_DATE,
  searchDate: searchDate
    ? { start: searchDate.start.unix(), end: searchDate.end.unix() }
    : null,
})

export const setSearchDatePickerOpenStatus = ({ focused }) => ({
  type: SET_SEARCH_DATE_PICKER_OPEN_STATUS,
  isSearchDatePickerOpen: focused,
})

export const loadMerchant = ({ token, cache, ssr = false }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/merchant/${token}`,
    types: LOAD_MERCHANT,
    cache,
    ssr,
  })

/**
 * Search Merchants
 */
export const searchMerchants = ({
  bucketType,
  bucketId,
  page = 1,
  sortBy,
  descOrAsc = 'asc',
  categoryBucketType,
  categoryBucketId,
  customFilter1FilterIds,
  customFilter2FilterIds,
  customFilter3FilterIds,
  marketplaceServiceType,
  startDate,
  endDate,
  distanceFilter,
}) => {
  let sort = null
  let order = 'asc'

  if (sortBy === 'rating') {
    sort = sortBy
    order = descOrAsc
  }

  // Default sort for suburb is distance
  if (!sort && bucketType === 'suburb') {
    sort = 'distance'
    order = 'asc'
  }

  return apiCall({
    method: 'GET',
    endpoint: '/marketplace/merchants',
    types: SEARCH_MERCHANTS,
    query: pickBy(
      {
        bucketType,
        bucketId,
        page,
        sortBy: sort,
        descOrAsc: order,
        categoryBucketType,
        categoryBucketId,
        customFilter1FilterIds,
        customFilter2FilterIds,
        customFilter3FilterIds,
        serviceType: `${marketplaceServiceType}`,
        startDate,
        endDate,
        distanceFilter,
      },
      identity,
    ),
  })
}

/**
 * Search Merchant Services
 */
export const searchServices = ({
  bucketType,
  bucketId,
  page = 1,
  categoryBucketType,
  categoryBucketId,
  marketplaceServiceType,
  startDate,
  endDate,
  customFilterOptions,
  distanceFilter,
  sortBy,
  descOrAsc = 'asc',
}) => {
  let sort = null
  let order = null

  if (sortBy === 'minimum_price') {
    sort = sortBy
    order = descOrAsc
  }

  return apiCall({
    method: 'GET',
    endpoint: '/marketplace/services',
    types: SEARCH_SERVICES,
    query: pickBy(
      {
        bucketType,
        bucketId,
        page,
        categoryBucketType,
        categoryBucketId,
        serviceType: `${marketplaceServiceType}`,
        startDate,
        endDate,
        customFilterOptions,
        distanceFilter,
        sort,
        order,
      },
      identity,
    ),
  })
}

export const loadNearMerchants = ({ lat, lng }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/location_near_me`,
    types: SEARCH_NEAR_MERCHANTS,
    ...(lat && lng ? { query: { lat, lng } } : {}),
    cache: lat && lng,
  })

export const widgetAuthenticate = token =>
  apiCall({
    method: 'POST',
    endpoint: `/marketplace/authenticate?token=${token}`,
    types: WIDGET_AUTHENTICATE,
    query: {
      token,
    },
  })

export const loadServicesAvailability = ({ date, services }) =>
  apiCall({
    method: 'POST',
    endpoint: `/marketplace/services/availability`,
    types: LOAD_SERVICES_AVAILABILITY,
    payload: {
      date,
      service_ids: services,
    },
  })

export const loadServicesNextAvailableDate = ({ services, searchDate }) =>
  apiCall({
    method: 'POST',
    endpoint: `/marketplace/services/next_active_date`,
    types: LOAD_SERVICES_NEXT_AVAILABLE_DATE,
    payload: {
      start_date: searchDate
        ? searchDate.start.format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD'),
      end_date: searchDate
        ? searchDate.end.format('YYYY-MM-DD')
        : moment().add(12, 'M').format('YYYY-MM-DD'),
      service_ids: services,
    },
  })

export const loadServiceSurroundingDates = ({ date, serviceId }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/service/surrounding_dates`,
    types: LOAD_SERVICE_SURROUNDING_DATES,
    query: {
      date,
      service_id: serviceId,
    },
  })

export const loadService = ({ token, cache, ssr = false }) =>
  apiCall({
    method: 'GET',
    endpoint: `/marketplace/service/${token}`,
    types: LOAD_SERVICE,
    cache,
    ssr,
  })
