import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'
import { useLocation } from 'react-router'
import { get, isEqual, map } from 'lodash'
import moment from 'moment'

import { FILTER_DATE_RANGE_OPTION } from 'Constants/ids'
import { useBaseTheme } from 'Hooks'

import { Box, Radio } from 'Components/UI'

import {
  Container,
  Content,
  Header,
  Label,
  RadioLabel,
  Item,
  ClearIcon,
  RadioHolder,
} from './styles'

const getDateRangeFromOption = option => {
  switch (option) {
    case FILTER_DATE_RANGE_OPTION.TODAY:
      return {
        start: moment(),
        end: moment(),
      }
    case FILTER_DATE_RANGE_OPTION.TOMORROW:
      return {
        start: moment().add(1, 'days'),
        end: moment().add(1, 'days'),
      }
    case FILTER_DATE_RANGE_OPTION.THIS_WEEK:
      return {
        start: moment(), // Current day
        end: moment().endOf('isoWeek'), // Upcoming Sunday
      }
    case FILTER_DATE_RANGE_OPTION.THIS_WEEKEND:
      return {
        start: moment().startOf('isoWeek').day(6), // Upcoming Saturday
        end: moment().startOf('isoWeek').day(7), // Upcoming Sunday
      }
    case FILTER_DATE_RANGE_OPTION.NEXT_WEEKEND:
      return {
        start: moment().add(1, 'weeks').startOf('isoWeek').add(5, 'days'), // Next week's Saturday
        end: moment().add(1, 'weeks').startOf('isoWeek').add(6, 'days'), // Next week's Sunday
      }
    case FILTER_DATE_RANGE_OPTION.THIS_MONTH:
      return {
        start: moment(),
        end: moment().endOf('month'),
      }
    default:
      return null
  }
}

const FilterDateRange = ({ searchDate, onChange }) => {
  const { secondaryColor } = useBaseTheme()
  const location = useLocation()

  const [value, setValue] = useState(
    get(location, 'state.dateRangeFilter', null),
  )

  const getDateRangeFilterOptions = useMemo(() => {
    return [
      {
        label: 'Today',
        value: FILTER_DATE_RANGE_OPTION.TODAY,
        visible: true,
      },
      {
        label: 'Tomorrow',
        value: FILTER_DATE_RANGE_OPTION.TOMORROW,
        visible: true,
      },
      {
        label: 'This Week',
        value: FILTER_DATE_RANGE_OPTION.THIS_WEEK,
        // Not visible if it's saturday or sunday
        visible: moment().isBefore(moment().startOf('isoWeek').day(6), 'day'),
      },
      {
        label: 'This Weekend',
        value: FILTER_DATE_RANGE_OPTION.THIS_WEEKEND,
        // Not visible if it's sunday
        visible: moment().isBefore(moment().startOf('isoWeek').day(7), 'day'),
      },
      {
        label: 'Next Weekend',
        value: FILTER_DATE_RANGE_OPTION.NEXT_WEEKEND,
        visible: true,
      },
      {
        label: 'This Month',
        value: FILTER_DATE_RANGE_OPTION.THIS_MONTH,
        // Not visible if it's the last day of the month
        visible: moment().isBefore(moment().endOf('month'), 'day'),
      },
      {
        label: 'Pick a date',
        value: FILTER_DATE_RANGE_OPTION.PICK_A_DATE,
        visible: true,
      },
    ].filter(option => option.visible)
  }, [searchDate])

  useEffect(() => {
    if (!searchDate) {
      setValue(null)

      return
    }

    // If the search date matches one of the options, show the radio as selected
    const optionToSelect = getDateRangeFilterOptions.find(option => {
      const dateRange = getDateRangeFromOption(option.value)

      if (!dateRange) {
        return false
      }

      return (
        searchDate.start.isSame(dateRange.start, 'day') &&
        searchDate.end.isSame(dateRange.end, 'day')
      )
    })

    setValue(optionToSelect?.value)
  }, [searchDate])

  const handleChange = option => {
    const dateRange = getDateRangeFromOption(option.value)

    onChange(option.value, dateRange)
  }

  const handleClear = () => {
    onChange(null, null)
  }

  return (
    <Container>
      <Header>
        <Label>Filter by date</Label>
      </Header>
      <Content>
        {map(getDateRangeFilterOptions, option => (
          <Item key={option.label}>
            <RadioHolder>
              <Radio
                checked={isEqual(option.value, value)}
                color={secondaryColor}
                input={{
                  value: option,
                  onChange: () => handleChange(option),
                }}
                name="date_range_filter"
              />
              <RadioLabel onClick={() => handleChange(option)}>
                {option.label}
              </RadioLabel>
              {isEqual(option.value, value) && (
                <Box ml={4}>
                  <ClearIcon color={secondaryColor} onClick={handleClear} />
                </Box>
              )}
            </RadioHolder>
          </Item>
        ))}
      </Content>
    </Container>
  )
}

FilterDateRange.defaultProps = {
  searchDate: null,
  onChange: () => {},
}

FilterDateRange.propTypes = {
  searchDate: PropTypes.shape({
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  }),
  onChange: PropTypes.func,
}

export default FilterDateRange
