import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DateRangePicker as ReactDatesDateRangePicker } from 'react-dates'
import moment from 'moment'

import { _ } from 'Services/I18n'
import { useBaseTheme } from 'Hooks'

import { CloseIcon, DatePickerWrapper, InfoButton, InfoWrapper } from './styles'

const propTypes = {
  date: PropTypes.object,
  rounded: PropTypes.number,
  shadow: PropTypes.number,
  onDateChange: PropTypes.func.isRequired,
}

const defaultProps = {
  date: null,
  rounded: 0,
  shadow: 0,
}

function DateRangePicker({ rounded, shadow, date, onDateChange, ...rest }) {
  const { primaryColor, secondaryColor } = useBaseTheme()
  const [focusedInput, setFocusedInput] = useState(null)
  const [startDate, setStartDate] = useState(date?.start)
  const [endDate, setEndDate] = useState(date?.end)

  useEffect(() => {
    setStartDate(date?.start)
    setEndDate(date?.end)
  }, [date])

  const handleOnDateRangeChange = ({
    startDate: newStartDate,
    endDate: newEndDate,
  }) => {
    // Always update the local state
    setStartDate(newStartDate)
    setEndDate(newEndDate)

    // Date has been cleared
    if (!newStartDate && !newEndDate) {
      onDateChange(null)
      return
    }

    // Ensure both dates are set
    if (!newStartDate || !newEndDate) {
      return
    }

    onDateChange({
      start: newStartDate,
      end: newEndDate,
    })
  }

  const handleOnFocusChange = focused => {
    setFocusedInput(focused)
  }

  const handleOnTodayClick = () => {
    onDateChange({
      start: moment(),
      end: moment(),
    })

    setFocusedInput(null)
  }

  const handleOnTomorrowClick = () => {
    onDateChange({
      start: moment().add(1, 'day'),
      end: moment().add(1, 'day'),
    })

    setFocusedInput(null)
  }

  const handleOnClearClick = () => {
    onDateChange()
    setFocusedInput(null)
  }

  const renderCalendarInfo = () => {
    return (
      <InfoWrapper>
        <InfoButton
          background={primaryColor}
          invert={1}
          rounded={rounded}
          onClick={handleOnTodayClick}
        >
          {_('datePicker.today')}
        </InfoButton>
        <InfoButton
          background={primaryColor}
          invert={1}
          rounded={rounded}
          onClick={handleOnTomorrowClick}
        >
          {_('datePicker.tomorrow')}
        </InfoButton>
        <InfoButton
          background={primaryColor}
          invert={1}
          rounded={rounded}
          onClick={handleOnClearClick}
        >
          {_('action.clear')}
        </InfoButton>
      </InfoWrapper>
    )
  }

  return (
    <DatePickerWrapper
      color={primaryColor}
      rounded={rounded}
      secondarycolor={secondaryColor}
      shadow={shadow}
    >
      <ReactDatesDateRangePicker
        customCloseIcon={<CloseIcon />}
        endDate={endDate}
        focusedInput={focusedInput}
        noBorder
        readOnly
        renderCalendarInfo={renderCalendarInfo}
        startDate={startDate}
        onDatesChange={handleOnDateRangeChange}
        onFocusChange={handleOnFocusChange}
        {...rest}
      />
    </DatePickerWrapper>
  )
}

DateRangePicker.displayName = 'DateRangePicker'
DateRangePicker.propTypes = propTypes
DateRangePicker.defaultProps = defaultProps

export default DateRangePicker
