import styled from 'styled-components'

import { calendarGlyph, longArrowRightGlyph } from 'Assets/Svg'

import { Icon } from 'Components/UI'
import themeGet from '@styled-system/theme-get'

export const DatePickerIcon = styled(Icon).attrs({
  glyph: calendarGlyph,
  size: 16,
})`
  vertical-align: unset;
`
export const DatePickerArrowIcon = styled(Icon).attrs({
  glyph: longArrowRightGlyph,
  size: 18,
})`
  vertical-align: unset;
  fill: ${() => themeGet('colors.gray')};
`
