import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'

import { _ } from 'Services/I18n'
import { useBaseTheme } from 'Hooks'
import { SERVICE_AVAILABILITY } from 'Constants/ids'

import {
  AvailabilityWrapper,
  AvailabilityLabel,
  AvailabilityDateLabel,
  CheckAvailabilityLabel,
  DatePickerIcon,
} from './styles'

const ServiceAvailability = ({
  availabilityDate,
  availability,
  isAvailabilityLoading,
  isNextAvailableDateLoading,
  isCustomBookingUrl,
  onShowAvailability,
  ...rest
}) => {
  const { showAvailability } = useBaseTheme()

  const isLoading = isAvailabilityLoading || isNextAvailableDateLoading

  const handleShowAvailability = event => {
    event.preventDefault()
    event.stopPropagation()

    onShowAvailability()
  }

  const getAvailabilityColor = () => {
    switch (availability) {
      case SERVICE_AVAILABILITY.AVAILABLE:
      case SERVICE_AVAILABILITY.BOOKING_REQUEST:
        return '#16A34A'
      default:
        return '#52525B'
    }
  }

  const renderForSpecificDate = () => (
    <>
      <AvailabilityDateLabel onClick={handleShowAvailability}>
        {availabilityDate.start.format('ddd D MMM')}
      </AvailabilityDateLabel>
      <AvailabilityLabel color={getAvailabilityColor()}>
        {availability}
      </AvailabilityLabel>
    </>
  )

  const renderNextAvailableDate = () => {
    const readOnly =
      (isCustomBookingUrl && availability) || availability?.includes(' - ')

    // Custom booking url and next available date (or end date is present)
    if (readOnly) {
      return (
        <AvailabilityDateLabel textDecoration="none">
          {availability}
        </AvailabilityDateLabel>
      )
    }

    // No end date or next available date
    if (!availability) {
      return (
        <CheckAvailabilityLabel onClick={handleShowAvailability}>
          {_('common.checkAvailability')}
        </CheckAvailabilityLabel>
      )
    }

    // Next available date (or end date) is present
    return (
      <AvailabilityDateLabel onClick={handleShowAvailability}>
        {availability}
      </AvailabilityDateLabel>
    )
  }

  const render = () => {
    if (availabilityDate) {
      return renderForSpecificDate()
    }

    return renderNextAvailableDate()
  }

  // If the client setting is configured to not show availability
  if (!showAvailability) {
    return null
  }

  if (isLoading) {
    return (
      <AvailabilityWrapper {...rest}>
        <DatePickerIcon color="#52525B" />
        <AvailabilityLabel>Searching...</AvailabilityLabel>
      </AvailabilityWrapper>
    )
  }

  if (isCustomBookingUrl) {
    // Hide availability if there is a custom booking URL
    // and a specific date is selected
    if (availabilityDate) {
      return null
    }

    // Hide availability if there is a custom booking URL and
    // there is no next available date (or end date)
    if (!availability) {
      return null
    }
  }

  return (
    <AvailabilityWrapper {...rest}>
      <DatePickerIcon color="#52525B" />
      {render()}
    </AvailabilityWrapper>
  )
}

ServiceAvailability.defaultProps = {
  availability: '',
  availabilityDate: null,
  isAvailabilityLoading: false,
  isNextAvailableDateLoading: false,
}

ServiceAvailability.propTypes = {
  availability: PropTypes.string,
  availabilityDate: PropTypes.shape({
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  }),
  isAvailabilityLoading: PropTypes.bool,
  isCustomBookingUrl: PropTypes.bool.isRequired,
  isNextAvailableDateLoading: PropTypes.bool,
  onShowAvailability: PropTypes.func.isRequired,
}

export default ServiceAvailability
