import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {
  HORIZONTAL_ORIENTATION,
  VERTICAL_ORIENTATION,
  INFO_POSITION_TOP,
  INFO_POSITION_BOTTOM,
} from 'react-dates/constants'

import { get, omit } from 'lodash'

import CustomizableCalendarDay from 'react-dates/lib/components/CustomizableCalendarDay'

import { useBaseTheme } from 'Hooks'
import Labels from 'Constants/labels'
import { DatePicker, DateRangePicker } from 'Components/UI'

import { DatePickerIcon, DatePickerArrowIcon } from './styles'

const propTypes = {
  date: PropTypes.object,
  focused: PropTypes.bool,
  rounded: PropTypes.number,
  shadow: PropTypes.number,
  small: PropTypes.bool,
  onDateChange: PropTypes.func.isRequired,
  onFocusChange: PropTypes.func.isRequired,
}

const defaultProps = {
  date: null,
  focused: false,
  rounded: 0,
  shadow: 0,
  small: true,
}

function SearchDatePicker(props) {
  const { shadow, small, date } = props
  const { labels, isPortableDevice, primaryColor, primaryTextColor } =
    useBaseTheme()

  const startOfToday = moment().startOf('day')
  const oneYearFromNow = moment().add('1', 'year')

  const handleIsOutsideRange = day => {
    return day.isBefore(startOfToday) || day.isAfter(oneYearFromNow)
  }

  const isDateRange = date && !date.start.isSame(date.end, 'day')

  const renderCalendarDay = dayProps => {
    const addAlpha = (hexColor, opacity) => {
      const color = hexColor.replace(`#`, ``)

      // Coerce values so it is between 0 and 1.
      const opacityValue = Math.round(
        Math.min(Math.max(opacity ?? 1, 0), 1) * 255,
      )

      return `#${color}${opacityValue.toString(16).toUpperCase()}`
    }

    const selectedStyles = {
      background: primaryColor,
      border: `1px solid ${primaryColor}`,
      color: primaryTextColor,

      hover: {
        background: addAlpha(primaryColor, 0.9),
        border: `1px solid ${addAlpha(primaryColor, 0.9)}`,
        color: primaryTextColor,
      },
    }

    const customDayStyles = {
      selectedStartStyles: selectedStyles,
      selectedEndStyles: selectedStyles,

      hoveredSpanStyles: {
        background: addAlpha(primaryColor, 0.6),
        border: `1px solid ${addAlpha(primaryColor, 0.4)}`,
        color: primaryTextColor,
      },

      selectedStyles: isDateRange
        ? {
            background: addAlpha(primaryColor, 0.5),
            border: `1px solid ${addAlpha(primaryColor, 0.4)}`,
            color: primaryTextColor,

            hover: {
              background: addAlpha(primaryColor, 0.7),
              border: `1px solid ${addAlpha(primaryColor, 0.4)}`,
              color: primaryTextColor,
            },
          }
        : selectedStyles,
    }

    return <CustomizableCalendarDay {...dayProps} {...customDayStyles} />
  }

  return isDateRange ? (
    <DateRangePicker
      customArrowIcon={<DatePickerArrowIcon />}
      customInputIcon={shadow && small ? null : <DatePickerIcon />}
      date={date}
      displayFormat="ddd, D MMM"
      enableOutsideDays
      endDateId="date_picker_end"
      hideKeyboardShortcutsPanel
      isOutsideRange={handleIsOutsideRange}
      minimumNights={0}
      numberOfMonths={2}
      orientation={
        isPortableDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
      }
      renderCalendarDay={renderCalendarDay}
      showClearDates
      startDateId="date_picker_start"
      verticalSpacing={12}
      withFullScreenPortal={isPortableDevice}
      withPortal={!isPortableDevice}
      {...omit(props, ['focused', 'onFocusChange'])}
    />
  ) : (
    <DatePicker
      calendarInfoPosition={
        isPortableDevice ? INFO_POSITION_TOP : INFO_POSITION_BOTTOM
      }
      customInputIcon={shadow && small ? null : <DatePickerIcon />}
      date={date?.start}
      displayFormat="ddd, D MMM"
      enableOutsideDays
      hideKeyboardShortcutsPanel
      id="date_picker"
      isOutsideRange={handleIsOutsideRange}
      numberOfMonths={2}
      orientation={
        isPortableDevice ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION
      }
      placeholder={get(labels, Labels.PLACEHOLDER_DATE_PICKER, 'Any Date')}
      renderCalendarDay={renderCalendarDay}
      showClearDate
      verticalSpacing={12}
      withFullScreenPortal={isPortableDevice}
      withPortal={!isPortableDevice}
      {...omit(props, ['date'])}
    />
  )
}

SearchDatePicker.propTypes = propTypes
SearchDatePicker.defaultProps = defaultProps

export default SearchDatePicker
