import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Flex, Box, Icon } from 'Components/UI'
import { closeGlyph } from 'Assets/Svg'

export const Container = styled(Box).attrs({
  px: [3, 3, 24],
  pt: [3, 3, 24],
})`
  background-color: ${themeGet('colors.white')};
`

export const Header = styled(Flex).attrs({
  justifyContent: 'space-between',
  alignItems: 'center',
})`
  width: 100%;
`

export const Label = styled(Box).attrs({})`
  font-size: 14px;
  font-weight: 700;
  color: ${themeGet('color.black')};
  line-height: 1;
  text-transform: lowercase;

  &:first-letter {
    text-transform: capitalize;
  }
`

export const Content = styled(Box).attrs({
  pb: 2,
  pt: 20,
})`
  border-bottom: 1px solid ${themeGet('colors.concrete')};
`

export const RadioHolder = styled(Flex).attrs({
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: 1,
})`
  position: relative;
  cursor: pointer;
`

export const RadioLabel = styled(Box).attrs({})`
  font-size: 14px;
  color: ${themeGet('colors.text')};
  line-height: 1.2;
  cursor: pointer;
  flex: 1;
`

export const Item = styled(Flex).attrs(({ alignItems }) => ({
  alignItems: alignItems || 'flex-start',
  pb: '12px',
}))`
  label {
    margin-bottom: 0;
    align-items: flex-start;
    width: auto;

    input {
      margin-right: 14px;

      &:not(checked):after {
        border: 2px solid #cfd9e0;
        background-color: ${themeGet('colors.white')};
      }
    }

    > div {
      font-family: ${themeGet('font')};
      font-size: 14px;
      font-weight: 200;
      letter-spacing: 0.5px;
      color: ${themeGet('colors.black')};
    }
  }
`

export const ClearIcon = styled(Icon).attrs({
  glyph: closeGlyph,
  size: 16,
})`
  cursor: pointer;
  fill: ${props => (props.color ? props.color : themeGet('colors.gray'))};

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`
