import React from 'react'
import PropTypes from 'prop-types'
import momentPropTypes from 'react-moment-proptypes'

import { _ } from 'Services/I18n'

import { useBaseTheme, useHydrated } from 'Hooks'

import {
  Container,
  Content,
  Title,
  List,
  ListItem,
  NearSearch,
  MapIcon,
  ImageHolder,
  NotFoundImage,
} from './styles'

const NotFound = ({
  hideImage,
  hideNearMe,
  onFallback,
  searchDate,
  bucket,
  category,
  ...rest
}) => {
  const hydrated = useHydrated()
  const { roundedBorder, secondaryColor } = useBaseTheme()

  if (!hydrated) {
    return null
  }

  const forLabel = 'for '
  const categoryLabel = category ? forLabel + category : ''

  const inLabel = 'in '
  const locationLabel = bucket ? inLabel + bucket : ''

  const onLabel = 'on '
  const betweenLabel = 'between '

  let dateLabel = ''

  if (searchDate) {
    if (searchDate.start.isSame(searchDate.end, 'day')) {
      dateLabel = onLabel + searchDate.start.format('ddd, D MMM')
    } else {
      dateLabel = `${betweenLabel}${searchDate.start.format('ddd, D MMM')} and ${searchDate.end.format('ddd, D MMM')}`
    }
  }

  return (
    <Container roundedborder={roundedBorder ? 1 : 0} {...rest}>
      {!hideImage && (
        <ImageHolder>
          <NotFoundImage />
        </ImageHolder>
      )}
      <Content>
        <Title>
          {_('common.resultsNotFoundWithValues', {
            categoryLabel,
            locationLabel,
            dateLabel,
          })}
        </Title>

        <List>
          <ListItem>{_('common.tryClearing')}</ListItem>
          {!hideNearMe && <ListItem>{_('common.tryDifferent')}</ListItem>}
          <ListItem>{_('common.tryUsing')}</ListItem>
          {searchDate && <ListItem>{_('common.tryDate')}</ListItem>}
        </List>
        <NearSearch color={secondaryColor} onClick={onFallback}>
          {hideNearMe ? (
            <>{_('common.showAll')}</>
          ) : (
            <>
              <MapIcon />
              {_('common.searchNearMe')}
            </>
          )}
        </NearSearch>
      </Content>
    </Container>
  )
}

NotFound.defaultProps = {
  bucket: null,
  hideImage: false,
  hideNearMe: false,
  searchDate: null,
  category: null,
}

NotFound.propTypes = {
  bucket: PropTypes.string,
  category: PropTypes.string,
  hideImage: PropTypes.bool,
  hideNearMe: PropTypes.bool,
  searchDate: PropTypes.shape({
    start: momentPropTypes.momentObj,
    end: momentPropTypes.momentObj,
  }),
  onFallback: PropTypes.func.isRequired,
}

export default NotFound
