import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Text, Flex, Box } from 'Components/UI'

export const ContentHeaderInner = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'flex-start',
})`
  & > div {
    overflow: hidden;
  }

  width: 100%;

  svg {
    margin: 0;
  }

  a {
    text-decoration: none;
    letter-spacing: 0.01em;
    outline: none;
    background: transparent;
    color: ${themeGet('colors.boulder')};
    font-size: 14px;
  }

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    a {
      font-size: 12px;
      color: ${themeGet('colors.tealBlue')};
    }
  }

  @media print {
    display: none;
  }
`

export const Icon = styled(Box).attrs({
  fontSize: 5,
  mr: 3,
})``

const titleColorCss = ({ linkcolor }) =>
  linkcolor &&
  css`
    a {
      color: ${themeGet('colors.boulder')};
    }
  `

export const Title = styled(Text).attrs({
  fontSize: [12, 12, 14, 14],
})`
  letter-spacing: 0.5px;
  color: ${themeGet('colors.boulder')};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${titleColorCss};
`
