import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'
import { maxWidth } from 'styled-system'

import { Flex, Box, Text, Icon } from 'Components/UI'
import { longArrowLeftGlyph } from 'Assets/Svg'
import { CONTENT_WIDTH, CONTENT_PADDING_X } from 'Constants/ids'

export const WrapperContainer = styled(Flex)`
  width: 100%;
  max-width: ${CONTENT_WIDTH}px;
  position: relative;
  margin: 0 auto;
  justify-content: center;
  padding-bottom: 32px;
`
export const Container = styled(Flex).attrs({
  mb: 16,
})`
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${CONTENT_WIDTH};
  ${maxWidth};

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    max-width: 100vw;
    margin: 0;
  }
`

export const Responsive = styled(Box).attrs({
  px: CONTENT_PADDING_X,
})`
  width: 100%;
  max-width: ${CONTENT_WIDTH}px;
`

export const MainContent = styled(Flex).attrs({
  my: 16,
  pt: 16,
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const MerchantsInnerContainer = styled(Flex)`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  width: 100%;
`
export const Title = styled(Text).attrs({
  mb: 24,
})`
  color: black;
  font-family: ${({ font }) => font || 'Inter, MarkPro, Inter, sans-serif'};

  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 45px */
`

export const BackIcon = styled(Icon).attrs({
  glyph: longArrowLeftGlyph,
  size: 16,
  mr: 2,
})`
  fill: currentColor;
`

export const BackButton = styled(Flex).attrs({
  mr: 3,
  display: ['none', 'none', 'flex'],
})`
  color: ${({ color }) => color || themeGet('colors.tealBlue')};
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.5px;
  white-space: nowrap;
  line-height: normal;
`

export const Row = styled(Flex).attrs({
  alignItems: 'flex-end',
})`
  width: 100%;
`
export const PrintContainer = styled(Box)`
  display: none;

  @media print {
    display: block;
  }
`
