import styled from 'styled-components'
import { color, textAlign } from 'styled-system'
import { themeGet } from '@styled-system/theme-get'

import { Box, Flex, Title } from 'Components/UI'
import { CONTENT_WIDTH, CONTENT_PADDING_X } from 'Constants/ids'

export const Container = styled(Flex).attrs({
  is: 'footer',
})`
  background-color: ${({ background }) =>
    background || themeGet('colors.black')};
  width: 100%;
`

export const Content = styled(Flex).attrs({
  px: CONTENT_PADDING_X,
  py: [20, 20, 48],
})`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 206px;
  max-width: ${CONTENT_WIDTH}px;
  margin: 0 auto;

  @media print {
    display: none;
  }
`

export const InnerContainer = styled(Flex).attrs({
  flexDirection: ['column', 'column', 'row'],
  justifyContent: 'space-between',
})`
  width: 100%;
  max-width: 1600px;
  min-height: 50px;

  font-family: Inter, sans-serif;
`

export const LeftUl = styled(Box).attrs({
  is: 'ul',
  mt: 0,
  ml: 0,
  p: 0,
  mr: 16,
  mb: 16,
})`
  list-style: none;
`

export const RightUl = styled(Box).attrs({
  mt: [48, 48, 0],
})``

export const LeftLi = styled(Box).attrs({
  is: 'li',
  mb: 10,
  fontSize: 1,
})``

export const Link = styled(Box).attrs({
  is: 'a',
  rel: 'noopener noreferrer',
})`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.8px;
  color: ${themeGet('colors.tealBlue')};
  text-decoration: none;

  ${color};
`

export const ColumnTitle = styled(Title.H3).attrs({
  fontSize: 15,
  mb: 10,
})`
  color: ${themeGet('colors.white')};
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.2px;

  ${textAlign};
  ${color};
`

export const SocialUl = styled(Box).attrs({
  is: 'ul',
  my: 0,
  p: 0,
})`
  list-style: none;
  display: flex;
`

export const SocialLi = styled(Box).attrs({
  is: 'li',
  mr: 3,
})`
  &:last-child {
    margin-right: 0;
  }
`

export const SocialLink = styled(Flex).attrs({
  is: 'a',
  justifyContent: 'center',
  alignItems: 'center',
})`
  color: ${themeGet('colors.white')};

  @media screen and (max-width: ${themeGet('breakpoints.1')}) {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: ${themeGet('colors.mineShaft')};
    color: ${themeGet('colors.white')};

    :hover {
      background-color: ${themeGet('colors.white')};
      transition: all ${themeGet('transitionTime')};

      > svg {
        fill: ${themeGet('colors.black')};
        color: ${themeGet('colors.black')};
      }
    }
  }

  ${color};
`
