import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { loadWishlist } from 'Store/Actions/wishlist'

import {
  isLoading,
  getWishlist,
  getWishlistError,
  getWishlistCount,
} from 'Store/Selectors/wishlist'

import Wishlist from './Wishlist'

const selector = createStructuredSelector({
  isLoading,
  wishlist: getWishlist,
  wishlistCount: getWishlistCount,
  wishlistError: getWishlistError,
})

const mapDispatchToProps = dispatch => ({
  onLoadWishlist: tokens => dispatch(loadWishlist(tokens)),
  dispatch,
})

export default connect(selector, mapDispatchToProps)(Wishlist)
