import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { closeAltGlyph } from 'Assets/Svg'

import Icon from '../Icon'
import ButtonAlt from '../ButtonAlt'
import Flex from '../Flex'

const roundedCss = ({ rounded }) => css`
  border-radius: ${rounded
    ? themeGet('borderRadius.2')
    : themeGet('borderRadius.1')};
`

export const CloseIcon = styled(Icon).attrs({
  glyph: closeAltGlyph,
  size: 20,
})`
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  stroke-width: 0;
  color: ${themeGet('colors.gray')};
`

export const InfoWrapper = styled(Flex).attrs({
  px: 22,
  pb: 20,
  pt: [20, 20, 0],
  justifyContent: ['center', 'center', 'center'],
})`
  gap: 10px;
`

export const InfoButton = styled(ButtonAlt).attrs({})`
  text-transform: capitalize;
  ${roundedCss};
`

export const DatePickerWrapper = styled(Flex)`
  background-color: #ffffff;
  border-radius: 4px;
  width: 100%;

  ${roundedCss}

  .SingleDatePicker {
    width: 100%;
    max-height: 55px;

    @media screen and (max-width: ${themeGet('breakpoints.0')}) {
      max-height: 43px;
    }
  }

  .SingleDatePickerInput {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 4px;
    border: 1px solid ${themeGet('colors.lightGrey')} !important;

    ${roundedCss}

    .DateInput {
      flex: 1;
      border-radius: 4px;
      ${roundedCss}

      input {
        color: ${themeGet('colors.header')};
        font-size: 14px;
        font-weight: 500;
        margin: 0 auto;
        padding-left: 0;
        cursor: default;
        border: none;
        height: 100%;

        ${roundedCss}

        &::placeholder {
          color: ${themeGet('colors.header')};
          font-size: 14px;
          font-weight: 500;
        }

        @media screen and (max-width: ${themeGet('breakpoints.2')}) {
          font-size: 14px;
          height: 38px;
          padding: 6px 7px 6px 23px;

          &::placeholder {
            font-size: 14px;
          }
        }

        @media screen and (max-width: ${themeGet('breakpoints.0')}) {
          font-size: 14px;
          padding: 6px 7px 6px 23px;

          &::placeholder {
            font-size: 14px;
          }
        }
      }

      &_input__focused {
        border-bottom: none;
      }

      .DateInput_fang {
        display: none;
      }
    }

    &_calendarIcon {
      color: ${props => props.secondarycolor};
      margin: 0 0 0 10px;

      @media screen and (max-width: ${themeGet('breakpoints.0')}) {
        margin: 0;
      }
    }
  }

  .DateInput_input {
    padding: 11px;
  }

  .DateInput_input__small {
    padding: 5px 7px;
    height: 36px !important;
  }

  .SingleDatePicker_picker,
  .DayPicker,
  .DayPicker_transitionContainer,
  .CalendarMonthGrid,
  .CalendarMonth {
    ${roundedCss}
  }
`
