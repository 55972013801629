import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'

import get from 'lodash/get'
import map from 'lodash/map'

import Labels from 'Constants/labels'
import { WISHLIST_TYPE } from 'Constants/ids'
import { withAppContext } from 'Services/Context'
import { _ } from 'Services/I18n'
import WishlistPersist from 'Services/WishlistPersist'

import {
  Breadcrumbs,
  ErrorContent,
  MerchantGridCard,
  ServiceCard,
} from 'Components/Blocks'

import { Box, Loader } from 'Components/UI'
import EmptyWishlist from './EmptyWishlist'
import ServicePrint from './ServicePrint'

import {
  Container,
  MainContent,
  MerchantsInnerContainer,
  Responsive,
  WrapperContainer,
  Title,
  BackIcon,
  BackButton,
  Row,
} from './styles'

class Wishlist extends PureComponent {
  state = {}

  wishlistPersist = new WishlistPersist()

  async componentDidMount() {
    const { wishlistCount, onLoadWishlist } = this.props

    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (wishlistCount) {
      onLoadWishlist(this.wishlistPersist.get()).then(this.syncToken)
    }
  }

  // Sync the tokens stored with the response to ensure any deactivated merchants are removed
  syncToken = result => {
    if (result?.status !== 200) {
      return
    }

    const { dispatch } = this.props
    const wishlistResult = get(result, 'data', { merchants: [], services: [] })

    // Change wishlist in local storage
    this.wishlistPersist.syncTokens(
      {
        merchants: get(wishlistResult, WISHLIST_TYPE.MERCHANT, []).map(
          wishlistItem => wishlistItem.token,
        ),
        services: get(wishlistResult, WISHLIST_TYPE.SERVICE, []).map(
          wishlistItem => wishlistItem.service.token,
        ),
      },
      dispatch,
    )
  }

  getBreadCrumbs = () => {
    const links = [
      { id: 0, label: _('common.home'), path: '/' },
      { id: 1, label: _('wishlist.wishlist') },
    ]

    return map(links, link => {
      return {
        id: link.id,
        element: link.path ? (
          <NavLink
            exact
            key={link.id}
            to={{
              pathname: link.path,
            }}
          >
            {link.label}
          </NavLink>
        ) : (
          link.label
        ),
      }
    })
  }

  handleGoBack = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { baseTheme, isLoading, wishlist, wishlistCount, wishlistError } =
      this.props

    const primaryFont = get(baseTheme, 'colors.primary_font')
    const secondaryBackgroundColor = get(
      baseTheme,
      'colors.secondary_background',
    )

    if (isLoading) {
      return <Loader color={get(baseTheme, 'colors.secondary_background')} />
    }

    if (wishlistError) {
      return <ErrorContent error={wishlistError} onBack={this.handleGoBack} />
    }

    const serviceResults = get(wishlist, WISHLIST_TYPE.SERVICE, [])
    const merchantResults = get(wishlist, WISHLIST_TYPE.MERCHANT, [])

    const services = () => {
      if (!serviceResults.length) {
        return null
      }

      return (
        <>
          <Title font={primaryFont} width={wishlistCount ? '100%' : 'auto'}>
            {get(baseTheme, `labels.${Labels.WISHLIST_SERVICES}`)}
          </Title>
          <Box width="100%">
            {map(serviceResults, result => (
              <ServicePrint
                key={`print-${result.service.token}`}
                service={result}
              />
            ))}
          </Box>
          <MerchantsInnerContainer>
            {map(serviceResults, result => (
              <ServiceCard
                className="hide-print"
                compact
                key={result.service.token}
                service={result}
                withAvailability={false}
              />
            ))}
          </MerchantsInnerContainer>
        </>
      )
    }

    const merchants = () => {
      if (!merchantResults.length) {
        return null
      }

      return (
        <Box className="hide-print" width="100%">
          <Title
            font={primaryFont}
            mt={serviceResults.length ? 32 : 0}
            width={wishlistCount ? '100%' : 'auto'}
          >
            {get(baseTheme, `labels.${Labels.WISHLIST_BUSINESSES}`)}
          </Title>
          <MerchantsInnerContainer>
            {map(merchantResults, result => (
              <MerchantGridCard
                height="auto"
                key={result.token}
                merchant={result}
              />
            ))}
          </MerchantsInnerContainer>
        </Box>
      )
    }

    return (
      <WrapperContainer>
        <Container>
          <Responsive>
            <Row>
              <BackButton
                color={secondaryBackgroundColor}
                onClick={this.handleGoBack}
              >
                <BackIcon />
                {_('action.back')}
              </BackButton>
              <Breadcrumbs
                display={['none', 'none', 'flex']}
                links={this.getBreadCrumbs()}
              />
            </Row>
            <MainContent>
              {wishlistCount ? (
                <>
                  {services()}
                  {merchants()}
                </>
              ) : (
                <EmptyWishlist />
              )}
            </MainContent>
          </Responsive>
        </Container>
      </WrapperContainer>
    )
  }
}

Wishlist.defaultProps = {
  wishlist: null,
  wishlistError: null,
}

Wishlist.propTypes = {
  baseTheme: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  wishlist: PropTypes.object,
  wishlistCount: PropTypes.number.isRequired,
  wishlistError: PropTypes.object,
  onLoadWishlist: PropTypes.func.isRequired,
}

export default withRouter(withAppContext(Wishlist))
