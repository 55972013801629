import React, { useCallback } from 'react'
import { useHistory } from 'react-router'

import { useBaseTheme } from 'Hooks'
import { _ } from 'Services/I18n'
import { PUBLIC_PATHS } from 'Constants/paths'
import { processBucketForAllResultsSearchPathParams } from 'Services/Utils/merchants'
import { Icon } from 'Components/UI'
import { heartGlyph } from 'Assets/Svg'

import {
  EmptyContainer,
  EmptyTitle,
  EmptyImage,
  EmptyText,
  StyledButton,
} from './styles'

function EmptyWishlist() {
  const { primaryColor, primaryTextColor, roundedBorder } = useBaseTheme()
  const history = useHistory()

  const handleGoToSearch = useCallback(() => {
    const params = processBucketForAllResultsSearchPathParams()

    history.push(PUBLIC_PATHS.SEARCH_QUERY(params))
  }, [])

  return (
    <EmptyContainer>
      <EmptyTitle>{_('wishlist.empty.title')}</EmptyTitle>
      <EmptyImage />
      <EmptyText>
        {_('wishlist.empty.text1')}
        <Icon glyph={heartGlyph} size={16} />
        {_('wishlist.empty.text2')}
      </EmptyText>

      <StyledButton
        background={primaryColor}
        color={primaryTextColor}
        roundedborder={roundedBorder}
        onClick={handleGoToSearch}
      >
        {_('common.startSearching')}
      </StyledButton>
    </EmptyContainer>
  )
}

export default EmptyWishlist
